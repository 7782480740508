import { useRouter } from 'next/router';
import { Index } from 'react-instantsearch-core';
import { AlgoliaConfig } from '@frontastic-engbers/types/engbers-custom';

export const useLocalizedIndex = (config: AlgoliaConfig) => {
  const router = useRouter();
  const locale = router?.locale || router?.defaultLocale;

  const indexes = {
    de: config.indexName + '_default',
  };

  return indexes[locale as 'de'];
};

const LocalizedIndex = ({ children, config }) => {
  const indexName = useLocalizedIndex(config);

  return <Index indexName={indexName}>{children}</Index>;
};

export default LocalizedIndex;
