import React from 'react';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import Toaster from '@engbers/components/online-shops/commercetools-ui/toaster';
import { FrontasticProvider, URLHistoryProvider } from '@frontastic-engbers/lib';
import 'tailwindcss/tailwind.css';
import '../styles/app.scss';
import '../styles/components/slider.css';
import '../styles/components/default-loader.css';

function FrontasticStarter({ Component, pageProps }: AppProps) {
  return (
    <FrontasticProvider context="shop">
      <URLHistoryProvider>
        <Component {...pageProps} />
        <Toaster />
      </URLHistoryProvider>
    </FrontasticProvider>
  );
}

export default appWithTranslation(FrontasticStarter);
