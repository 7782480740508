import { useRouter } from 'next/router';
import React, { createContext, useState, useEffect, useContext, useRef } from 'react';

interface HValidation {
  history: string[];
  setHistory(data: string[]): void;
  back(): void;
}

const URLHistoryContext = createContext<HValidation>({} as HValidation);
export const URLHistoryProvider: React.FC = ({ children }) => {
  const { asPath, push, pathname, beforePopState } = useRouter();
  const [history, setHistory] = useState<string[]>([]);
  const willBePop = useRef(false);

  useEffect(() => {
    beforePopState(({ url, as, options }) => {
      // Detect browsers go back action
      if (history[history.length - 2] === as) {
        willBePop.current = true;
      }
      return true;
    });
  }, [beforePopState, history]);

  function back() {
    for (let i = history.length - 2; i >= 0; i--) {
      const route = history[i];
      if (!route.includes('#') && route !== pathname) {
        push(route);

        // if you want to pop history on back
        const newHistory = history.slice(0, i);
        setHistory(newHistory);

        break;
      }
    }
  }

  useEffect(() => {
    if (willBePop.current) {
      setHistory((previous) => [...previous.filter((item, index) => index !== previous.length - 1)]);
      willBePop.current = false;
    } else {
      setHistory((previous) => [...previous, asPath]);
    }
  }, [asPath]);

  return (
    <URLHistoryContext.Provider
      value={{
        back,
        history,
        setHistory,
      }}
    >
      {children}
    </URLHistoryContext.Provider>
  );
};

export function useHistory(): HValidation {
  const context = useContext(URLHistoryContext);
  return context;
}
